<template>
  <div class="self-procurement-register">
    <!-- 表单搜索 start -->
    <Filtersearch
      ref="Filtersearchref"
      :fromdata="formData"
      :list="Filtersearchlist"
      @Refresh="onHandleRefresh"
      @search="onSearch"
    >
      <template v-slot:right>
        <el-button
          type="warning"
          @click="exportHandle"
          icon="el-icon-download"
          :disabled="!tableData.length || disabledExport"
          >导出</el-button
        >
      </template>
    </Filtersearch>
    <!-- <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-date-picker
            v-model="add_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="添加时间始"
            end-placeholder="添加时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            v-model="deduction_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="扣款时间始"
            end-placeholder="扣款时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.owner_name"
            placeholder="请输入添加人"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.sku_code"
            placeholder="请输入货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.state"
            clearable
            placeholder="选择审核状态"
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.state"
            clearable
            placeholder="选择问题原因"
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.operate_name"
            placeholder="请输入审核人"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.remarks"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            @click="exportHandle"
            icon="el-icon-download"
            :disabled="!tableData.length || disabledExport"
            >导出</el-button
          >
        </div>
      </div>
    </div> -->
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <table-list
        v-loading="loading"
        :tableData="tableData"
        :is_audit="is_audit"
        @openEditGoods="openEditGoods"
        @onHandleShow="onHandleShow"
        @onsubmitRefresh="onsubmitRefresh"
      ></table-list>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑 start -->
    <edit-goods
      ref="editGoodsRef"
      @onsubmitRefresh="onsubmitRefresh"
      :typeList="typeList"
    ></edit-goods>
    <!-- 编辑 start -->

    <!-- 文件预览 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      :fileIndex="previewIndex"
      :fileType="fileType"
    ></c-preview-file>
    <!-- 文件预览 end -->
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list/index.vue";
import editGoods from "./modules/edit-goods/index.vue";
import { EXAMINE_STATUS_ENUM } from "./utils/enum/index.js";
import {
  postFleetQualityControlList,
  postFleetQcType,
} from "@/api/logisticsControl/quality-control-input/index.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { postFleetQualityControlExport } from "@/api/export/index.js";
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";
import Filtersearch from "@/components/Filtersearch.vue";

export default {
  name: "quality-control-input",
  components: { TableList, editGoods, CPreviewFile, Filtersearch },
  data() {
    return {
      Filtersearchlist: [
        {
          type: "daterange",
          name: "添加时间",
          msg: "",
          clearable: true,
          model: "add_at",
          startmodel: "start_date",
          endmodel: "end_date",
          startplaceholder: "添加时间始",
          endplaceholder: "添加时间止",
        },
        {
          type: "daterange",
          name: "扣款时间",
          msg: "",
          clearable: true,
          model: "deduction_at",
          startmodel: "deduction_start",
          endmodel: "deduction_end",
          startplaceholder: "扣款时间始",
          endplaceholder: "扣款时间止",
        },
        {
          type: "input",
          name: "添加人",
          clearable: true,
          model: "owner_name",
          placeholder: "请输入添加人",
        },
        {
          type: "input",
          name: "货号",
          clearable: true,
          model: "sku_code",
          placeholder: "请输入货号",
        },
        {
          type: "select",
          name: "审核状态",
          clearable: true,
          model: "state",
          placeholder: "请选择审核状态",
          label: "label",
          value: "value",
          selectoption: Object.values(EXAMINE_STATUS_ENUM),
        },
        {
          type: "input",
          name: "审核人",
          clearable: true,
          model: "operate_name",
          placeholder: "请输入审核人",
        },
        {
          type: "select",
          name: "问题原因",
          clearable: true,
          model: "qc_type",
          placeholder: "请选择问题原因",
          selectoptionname: "reasonList",
          label: "val",
          value: "index",
          selectoption: [],
        },
        {
          type: "input",
          name: "备注",
          clearable: true,
          model: "remarks",
          placeholder: "请输入备注",
        },
      ],
      stateList: Object.values(EXAMINE_STATUS_ENUM),
      EXAMINE_STATUS_ENUM,
      dialogVisible: false,
      fileList: [], // 预览文件列表
      previewIndex: 0, //预览文件下标
      fileType: "", // 预览文件类型
      loading: false,
      add_at: "",
      deduction_at: "",
      formData: {
        add_at: "",
        start_date: "", //添加日期始
        end_date: "", //添加日期止
        deduction_at: "",
        deduction_start: "", //扣款日期始
        deduction_end: "", //扣款日期止
        owner_name: "", //添加人
        remarks: "", //备注
        state: "", //审批状态
        operate_name: "", //审批人
        sku_code: "", // 货号
        qc_type: "", // 问题原因
        page: 1,
        pageSize: 50,
        source: 2,
      }, // 表单参数
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [], // 城市仓列表
      total: 0,
      disabledExport: false,
      is_audit: false, // 是否展示列表审核按钮权限
      typeList: [],
    };
  },
  mounted() {
    this.onInitData();
  },
  computed: {
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.url.lastIndexOf(".");
        const fileType = file.url.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
  },
  methods: {
    // 问题原因
    async postAjaxFleetQcType() {
      try {
        const res = await postFleetQcType();
        console.log(res, "res==");
        this.typeList = res.data || [];
        this.Filtersearchlist.forEach((el) => {
          if (el.type == "select" && el.selectoptionname == "reasonList") {
            el.selectoption = [{ index: "", val: "全部" }, ...res.data];
          }
        });
      } catch (error) {
        console.log(error, "postFleetQcType");
      }
    },
    /** 查看证据 文件预览 */
    onHandleShow(row) {
      let tmpVideoList = [];
      if (row.video_list.length > 0) {
        row.video_list.map((vItem) => {
          tmpVideoList.push({
            label: "视频",
            url: vItem,
          });
        });
      }
      let tmpImageList = [];
      if (row.image_list.length > 0) {
        row.image_list.map((mItem) => {
          tmpImageList.push({
            label: "图片",
            url: mItem,
          });
        });
      }
      this.fileList = tmpVideoList.concat(tmpImageList);
      console.log(this.fileList, "----");
      this.dialogVisible = true;
      this.fileType = this.getFileType(this.fileList[0]);
    },
    /** 编辑 */
    openEditGoods(data) {
      this.$refs.editGoodsRef.onInitData(data);
    },
    /** 初始化 */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.hqlist();
      this.postAjaxFleetQcType();
    },
    /** 时间转换 */
    hqtime() {
      //  添加日期
      if (this.add_at != null) {
        if (this.add_at.length > 0) {
          this.formData.start_date = this.add_at[0];
          this.formData.end_date = this.add_at[1];
        } else {
          this.formData.start_date = "";
          this.formData.end_date = "";
        }
      } else {
        this.formData.start_date = "";
        this.formData.end_date = "";
      }
      // 扣款日期
      if (this.deduction_at != null) {
        if (this.deduction_at.length > 0) {
          this.formData.deduction_start = this.deduction_at[0];
          this.formData.deduction_end = this.deduction_at[1];
        } else {
          this.formData.deduction_start = "";
          this.formData.deduction_end = "";
        }
      } else {
        this.formData.deduction_start = "";
        this.formData.deduction_end = "";
      }
    },
    /** 列表 */
    async hqlist() {
      this.hqtime();
      this.loading = true;
      try {
        const res = await postFleetQualityControlList(this.formData);
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.is_audit = res.data.is_audit;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    /** 导出 */
    async exportHandle() {
      this.hqtime();
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          ...this.formData,
        };
        delete params.page;
        delete params.pageSize;
        const res = await postFleetQualityControlExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax postFleetQualityControlExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.add_at = [];
      this.deduction_at = [];
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch(e) {
      this.formData = e;
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 刷新
     */
    onsubmitRefresh() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss">
.self-procurement-register {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
