<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    tooltip-effect="dark"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column prop="sku_code" width="100px" align="center" label="货号">
    </el-table-column>
    <el-table-column prop="supplier_name" align="center" label="供应商名称">
    </el-table-column>
    <el-table-column prop="create_time" align="center" label="添加时间">
    </el-table-column>
    <el-table-column prop="owner_name" align="center" label="添加人">
    </el-table-column>
    <el-table-column prop="quantity" align="center" label="件数">
    </el-table-column>
    <el-table-column prop="deduction_amt" align="center" label="扣款金额">
    </el-table-column>
    <el-table-column prop="video_list" align="center" label="视频证据">
      <template slot-scope="scope">
        <span
          @click="onHandleShow(scope.row)"
          style="color: #1389ae"
          class="pointer font-weight"
          >查看证据</span
        >
      </template>
    </el-table-column>
    <el-table-column prop="qc_type_line" align="center" label="问题原因">
    </el-table-column>
    <el-table-column
      prop="remarks"
      align="center"
      label="缺少斤数*毛单价"
      min-width="150"
    >
      <template slot-scope="scope">
        {{ scope.row.weight }}*{{ scope.row.weight_price }}
      </template>
    </el-table-column>
    <el-table-column prop="remarks" align="center" label="备注" width="300">
    </el-table-column>
    <el-table-column prop="operate_name" align="center" label="审核人">
    </el-table-column>
    <el-table-column prop="state_txt" align="center" label="审核状态">
    </el-table-column>
    <el-table-column prop="deduction_time" align="center" label="扣款时间">
    </el-table-column>
    <el-table-column label="操作" align="center" fixed="right" min-width="130">
      <template slot-scope="scope">
        <el-button
          type="text"
          @click="onEditGood(scope.row)"
          v-if="scope.row.state == EXAMINE_STATUS_ENUM['0'].value"
          >编辑</el-button
        >
        <el-button
          type="text"
          @click="onToExamine(scope.row)"
          v-if="is_audit && scope.row.state == EXAMINE_STATUS_ENUM['0'].value"
          >审核</el-button
        >
        <el-button
          type="text"
          @click="onCancel(scope.row)"
          v-if="scope.row.state == EXAMINE_STATUS_ENUM['0'].value"
          >作废</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { postFleetQualityControlAudit } from "@/api/logisticsControl/quality-control-input/index.js";
import { EXAMINE_STATUS_ENUM } from "../../utils/enum/index.js";
export default {
  name: "TableList",
  props: ["tableData", "is_audit"],
  components: {},
  data() {
    return {
      EXAMINE_STATUS_ENUM,
    };
  },
  methods: {
    /**
     * 审核
     */
    onToExamine(row) {
      console.log(row, "row===");
      this.$confirm("确定审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postAjaxFleetQualityControlAudit(row, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    /**
     * 作废
     */
    onCancel(row) {
      console.log(row, "row===");
      this.$confirm("确定作废吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postAjaxFleetQualityControlAudit(row, 2);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消作废",
          });
        });
    },
    /**
     * 审核 - 1  作废 - 2
     */
    async postAjaxFleetQualityControlAudit(e, state) {
      try {
        const res = await postFleetQualityControlAudit({
          id: e.id,
          state: state,
        });
        this.$message({
          type: "success",
          message: state == 2 ? "作废成功!" : "审核成功!",
        });
        this.$emit("onsubmitRefresh");
      } catch (error) {
        console.log(error, "postFleetQualityControlAudit");
      }
    },
    /**
     * 编辑
     */
    onEditGood(row) {
      this.$emit("openEditGoods", row);
    },
    /**
     * 查看证据
     */
    onHandleShow(row) {
      this.$emit("onHandleShow", row);
    },
  },
};
</script>
<style lang="scss" scoped></style>
