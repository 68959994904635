<template>
  <div>
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      class="dialog"
      center
      :append-to-body="true"
    >
      <div style="width: 80%" class="mt-10">
        <el-form
          :model="personForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="货号" prop="sku_code">
            <el-input
              v-model="personForm.sku_code"
              placeholder="请输入货号"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="件数" prop="quantity">
            <el-input
              v-model="personForm.quantity"
              placeholder="请输入件数"
            ></el-input>
          </el-form-item>
          <el-form-item label="缺货原因" prop="qc_type">
            <el-select
              v-model="personForm.qc_type"
              clearable
              placeholder="选择缺货原因"
              @change="typeChange"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.val"
                :value="item.index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="d-flex a-center" v-if="personForm.qc_type == 40">
            <div>
              <el-form-item label="缺货斤数" prop="weight">
                <el-input
                  v-model="personForm.weight"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="mb-15 ml10">
              毛单价{{ personForm.weight_price }}元/斤
            </div>
          </div>
          <div class="d-flex a-center">
            <div>
              <el-form-item label="扣款金额" prop="deduction_amt">
                <el-input
                  v-model="personForm.deduction_amt"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="mb-15 ml10">元</div>
          </div>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="personForm.remarks"
              :rows="3"
              placeholder="输入备注"
              maxlength="200"
              show-word-limit
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片证据" prop="image_list">
            <el-upload
              :on-preview="handlePictureCardPreview"
              :action="`${BASE.PRO2}/upload`"
              :headers="{ token: token }"
              accept=".jpg,.jpeg,.png"
              ref="upload"
              :class="{ hide: pic.length == 5 }"
              :on-success="successFile"
              :on-error="errorFile"
              :before-remove="beforeRemove"
              :on-exceed="onExceed"
              :on-change="onChange"
              list-type="picture-card"
              multiple
              :file-list="fileList"
              :limit="5"
            >
              <i slot="trigger" class="el-icon-plus"></i>
            </el-upload>
            <span style="margin-left: 20px">【{{ pic.length || 0 }}/5】</span>
          </el-form-item>
          <el-form-item prop="video_list" label="视频证据">
            <el-upload
              v-loading="uploadLoading"
              :action="`${BASE.PRO2}/upload`"
              :headers="{ token: token }"
              accept=".mp4,.avi,.flv,.mov"
              :class="{ hide: video.length >= 2 }"
              :on-success="successFile1"
              :on-error="errorFile1"
              :before-remove="beforeRemove1"
              :on-exceed="onExceed1"
              :on-change="onChange1"
              list-type="picture-card"
              multiple
              :show-file-list="false"
              :limit="2"
              :file-list="fileList1"
              :before-upload="beforeUpload"
            >
              <i slot="trigger" class="el-icon-plus"></i>
            </el-upload>
            <div class="d-flex">
              <section v-if="video.length" class="video-wrap">
                <div
                  v-for="(file, index) of video"
                  :key="index"
                  class="video-child"
                >
                  <video
                    ref="videoPlayer"
                    :src="file"
                    :controls="true"
                    :autoplay="true"
                    class="video pointer"
                  ></video>
                  <span
                    class="handle-delete"
                    @click="onHandleRemoveFile(file, index)"
                  >
                    <i class="el-icon el-icon-delete"></i>
                  </span>
                </div>
              </section>
              <span style="margin-left: 20px"
                >【{{ video.length || 0 }}/2】</span
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submitConfirm">确定</el-button>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="showImage">
      <img :src="bigImage" alt="" class="showImg" />
    </el-dialog>
  </div>
</template>
<script>
import { BASE } from "@/api";
import {
  postFleetQualityControlUpdate,
  postFleetQcDetail,
} from "@/api/logisticsControl/quality-control-input/index.js";

export default {
  name: "EditGoods",
  props: ["typeList"],
  components: {},
  data() {
    return {
      BASE,
      pic: [],
      video: [],
      fileList: [],
      fileList1: [],
      bigImage: "",
      showImage: false,
      token: sessionStorage.getItem("token"),
      dialogFormVisible: false,
      platFormTitle: "修改",
      info: {}, // 编辑对象
      personForm: {
        id: "",
        sku_code: "",
        quantity: "",
        remarks: "",
        image_list: [],
        video_list: [],
        qc_type: "",
        deduction_amt: "",
        weight: "",
        weight_price: "",
        source: 2,
      },
      isEdit: true, // 是否可编辑
      uploadLoading: false,
      rules: {
        sku_code: [{ required: true, message: "请输入货号", trigger: "blur" }],
        qc_type: [
          { required: true, message: "请选择缺货原因", trigger: "blur" },
        ],
        deduction_amt: [
          { required: true, message: "请输入扣款金额", trigger: "blur" },
        ],
        weight: [{ required: true, message: "请输入斤数", trigger: "blur" }],
        quantity: [
          {
            required: true,
            message: "请输入件数",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^(?:[1-9]\d*)$/.test(value) == false) {
                callback(new Error("请输入正整数"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        remarks: [{ required: true, message: "请输入备注", trigger: "blur" }],
        image_list: [
          {
            required: true,
            message: "请上传图片证据",
            trigger: ["blur", "change"],
          },
        ],
        video_list: [
          {
            required: true,
            message: "请上传视频证据",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    // 缺斤少两 斤数必填
    "personForm.qc_type"(val) {
      if (val == 40) {
        this.rules.weight.required = true;
      } else {
        this.rules.weight.required = false;
      }
    },
  },
  methods: {
    // 修改缺货原因
    typeChange(val) {
      console.log(val, "eee");
      this.personForm.weight = "";
    },
    // 获取详情
    async postAjaxFleetQcDetail(e) {
      try {
        const res = await postFleetQcDetail({ id: e.id });
        console.log(res, "res==");
        this.info = res.data;
        this.isEdit = false;
        for (let key in this.personForm) {
          this.personForm[key] = res.data[key];
        }

        if (res.data.image_list.length > 0) {
          res.data.image_list.map((dItem) => {
            this.fileList.push({ url: dItem });
          });
        }
        if (res.data.video_list.length > 0) {
          res.data.video_list.map((vItem) => {
            this.fileList1.push({ url: vItem });
          });
        }
        this.pic = res.data.image_list || [];
        this.video = JSON.parse(JSON.stringify(res.data.video_list)) || [];
      } catch (error) {
        console.log(error, "postFleetQcDetail");
      }
    },
    // 确定提交修改
    submitConfirm() {
      console.log(this.personForm, "personForm==");
      this.$refs.ruleForm.validate(async (valid) => {
        console.log(valid, "eee");
        // if (Number(this.personForm.quantity) > 20) {
        //   this.$message({
        //     message: "件数不能超过20件",
        //     type: "error",
        //   });
        //   return;
        // }
        if (valid) {
          try {
            const res = await postFleetQualityControlUpdate(this.personForm);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$emit("onsubmitRefresh");
            this.dialogFormVisible = false;
          } catch (error) {
            console.log(error, "postFleetQualityControlUpdate");
          }
        }
      });
    },
    /**
     * 图片超出数量处理
     */
    onExceed() {
      this.$message.warning(`抱歉，最多只能上传5张图片`);
    },
    /**
     * 视频超出数量处理
     */
    onExceed1() {
      this.$message.warning(`抱歉，最多只能上传2个视频`);
    },
    /**
     * 上传图片成功操作
     */
    successFile(e) {
      this.pic.push(e.data);
      let tmpImgList = [];
      this.pic.map((pItem) => {
        tmpImgList.push(pItem);
      });
      this.personForm.image_list = tmpImgList;
      console.log(this.pic, this.personForm.image_list, "---");
    },
    /**
     * 上传成功操作
     */
    successFile1(e, file, fileList) {
      console.log(e, "e===");
      if (e && e.data) {
        this.uploadLoading = false;
        this.video.push(e.data);
        let tmpVideoList = [];
        let tmpFileList = [];
        this.video.map((vItem) => {
          tmpVideoList.push(vItem);
          tmpFileList.push({ url: vItem });
        });
        this.personForm.video_list = tmpVideoList;
        this.fileList1 = fileList;
        this.uploadLoading =
          fileList.length == this.video.length ? false : true;
      } else {
        console.error("Invalid response data:", e);
      }
      console.log(
        "12222",
        this.video,
        this.personForm.video_list,
        this.fileList1,
        "---"
      );
    },
    /**
     * 上传失败
     */
    errorFile(e) {
      console.log(e, "e===");
    },
    /**
     * 上传失败
     */
    errorFile1(e) {
      console.log(e, "e===");
    },
    /**
     * 删除图片
     */
    beforeRemove(file, fileList) {
      console.log(file, fileList, "===");
      const index = fileList.findIndex((item) => item.uid === file.uid);
      if (index !== -1) {
        this.pic.splice(index, 1); // 删除数组中的元素
        this.personForm.image_list.splice(index, 1);
      }
      console.log(index, this.pic, this.personForm.image_list, "index===");
    },
    beforeUpload() {
      console.log("1234");
      this.uploadLoading = true;
    },
    /**
     * 删除文件
     */
    beforeRemove1(file, fileList) {
      console.log(file, fileList);
    },
    /**
     * 移除视频
     */
    onHandleRemoveFile(file, index) {
      console.log(file, index, this.fileList1, "===");
      this.video.splice(index, 1); // 删除数组中的元素
      this.personForm.video_list.splice(index, 1);
      this.fileList1.splice(index, 1);
      console.log(index, this.video, this.personForm.video_list, "index===");
    },
    // 上传图片监听
    onChange(file, fileList) {
      console.log(file, this.pic, fileList, this.fileList, "fileList==");
    },
    // 上传视频监听
    onChange1(file) {
      // console.log(file, this.video, "fileList==");
    },
    // 预览上传图片
    handlePictureCardPreview(file) {
      this.bigImage = file.url;
      this.showImage = true;
    },
    /**
     * 初始化
     */
    onInitData(data) {
      this.$refs.ruleForm?.clearValidate();
      this.postAjaxFleetQcDetail(data);
      // this.info = data;
      // this.isEdit = false;
      // for (let key in this.personForm) {
      //   this.personForm[key] = data[key];
      // }
      this.pic = []; // 编辑前清空图片
      this.video = []; // 编辑前清空视频
      this.fileList = [];
      this.fileList1 = [];
      // if (data.image_list.length > 0) {
      //   data.image_list.map((dItem) => {
      //     this.fileList.push({ url: dItem });
      //   });
      // }
      // this.pic = data.image_list || [];
      // this.video = data.video_list || [];
      this.dialogFormVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsWrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.totalWrap {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.hide {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
/** 视频样式 */
.video-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .video-child {
    margin-top: 10px;
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    box-sizing: border-box;
    video {
      width: 100%;
      height: 100%;
    }
    .handle-delete {
      line-height: normal !important;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
</style>
